.interceptorBoxCopy {
  all: initial;
  text-decoration: none !important;

  color: #fff7ef;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 44.8px */
  letter-spacing: 0.858px;
  padding: 24px;
}

.interceptorBox {
  all: initial;
  text-decoration: none !important;
  display: flex;
  flex-direction: row;
  width: 355px;
  height: 174px;
  flex-shrink: 0;
  border-radius: 18.027px;
  border: 5px solid #63785e;
  margin: 16px;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s ease-in-out;

  background: #394337;
}

.interceptorKora {
  width: 300px;
  height: 300px;
}

.interceptorBox2 {
  all: initial;
  text-decoration: none !important;
  display: flex;
  flex-direction: row;
  width: 355px;
  height: 174px;
  flex-shrink: 0;
  border-radius: 18.027px;
  border: 5px solid #63785e;
  margin: 16px;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s ease-in-out;

  background-color: rgba(57, 67, 55, 72%);
}

.interceptorBoxCopy a {
  text-decoration: none !important;
}

.App {
  background: linear-gradient(
    166deg,
    #ffdab1 1.29%,
    #e9bd8b 59.42%,
    #d6a269 112.47%
  );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  color: #394337; /* Added # before the hex value */
}

body,
html {
  height: 100%;
  padding: 0;
  overflow: hidden; /* This will hide any content that goes outside the body and html elements */
}

.kora_rive {
  height: 400px;
}

.kora_chat {
  height: 400px;
}

.seedling {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 40.473px;
  height: 101.311px;
  margin-top: auto;
  margin-right: 200px;
}

.seedling2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 60.473px;
  height: 101.311px;
  margin-top: auto;
  margin-right: 200px;
}

.seedling3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 72.473px;
  height: 101.311px;
  margin-top: auto;
  margin-right: 200px;
}

.bonsai {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 30%;
  height: 2%;
  margin-top: auto;
}

.landingButton {
  align-items: center;
  background-color: #394337;
  border-radius: 100px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center; /* Corrected from ecenter to center */
  overflow: hidden;
  padding: 12px 18px;
  position: relative;
  height: 40px;
  width: fit-content;
  color: white;
  margin-bottom: 40px;
}

.landingTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
}

.redirectHeader {
  display: flex;
  flex-direction: column;
  width: 400px;
  font-size: 36px;
  justify-content: center;
  align-content: center;
  text-align: center; /* Ensures the text inside .redirectHeader is centered if it spans multiple lines */
}

.jesuschrist {
  display: flex;
  flex-direction: column;
  width: 400px;
  font-size: 36px;
  justify-content: center;
  align-content: center;
}

.actionButton {
  border-radius: 32px;
  background: var(--Primary-1, #394337);
  display: inline-flex;
  padding: 15px 46px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  text-decoration: none;
  position: absolute;
  bottom: 60px;
  font-weight: 700;
}

.redirectButton {
  border-radius: 32px;
  display: inline-flex;
  padding: 15px 46px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #394337;
  text-decoration: underline;
  position: absolute;
  bottom: 60px;
  font-weight: 700;
}

.resultsButton {
  width: 180px;
  margin-bottom: 40px;
}

.button1 {
  align-items: center;
  background-color: #394337;
  border-radius: 60px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 33.752px 103.505px;
  position: relative;
  height: auto;
  width: fit-content;
  color: white;
  font-size: 34px;
  gap: 22.501px;
  margin-bottom: 200px;
}

.continue_button {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 20px;
}

.watermark {
  color: #394337;
  opacity: 0.4;
  margin-top: 0%;
  margin-bottom: 700px;
  position: absolute;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 15.6px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.312px;
}

.onboardingbutton {
  width: 90px;
  animation: fadeIn 2s ease-in-out;
  margin-bottom: -12%;
  padding-top: 12%;
}

.onboardingKora {
  height: 380px;
  width: 500px;
}

.interfaceKora {
  position: relative; /* Makes z-index applicable */
  z-index: 1; /* Lower z-index, so it stays behind */
  height: 420px;
  width: 360px;
  animation: fadeIn 1s ease-in-out;
}

.chatKora {
  position: relative; /* Makes z-index applicable */
  z-index: 1; /* Lower z-index, so it stays behind */
  height: 360px;
  width: 360px;
  animation: fade}

.results2Kora {

  height: 300px;
  width: 300px;
  animation: fadeIn 1s ease-in-out;
}

.registrationKora {
  height: 380px;
  width: 180px;
  animation: fadeIn 1s ease-in-out;
}

.kora_wave3 {
  height: 700px;
  width: 700px;
  bottom: 0px;
  margin-top: 19px;
  animation: fadeIn 3s ease-in-out;
}

.leaves_blowing {
  height: 50px;
  width: 600px;
}

.comingSoon {
  border-radius: 32px;
  background-color: rgba(99, 120, 94, 50%);
  display: inline-flex;
  padding: 15px 46px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
  margin: 2%;
  font-weight: 700;
}

.onboardingContent {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items horizontally in the container */
  align-items: center; /* Center items vertically in the container */
  height: 100vh; /* Make the container full height of the viewport */
  text-align: center; /* Center text within the items, if needed */
  margin-top: 1%;
}

.interceptorContent {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items horizontally in the container */
  align-items: center; /* Center items vertically in the container */
  height: 100vh; /* Make the container full height of the viewport */
  text-align: center; /* Center text within the items, if needed */
  margin-top: -16%;
}

.resultsContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 2%;
}

.landingContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing1 {
  animation: fadeIn 1s ease-in-out;
  font-size: 36px;
}

.landing2 {
  animation: fadeIn 3s ease-in-out;
  color: #63785e;
  text-align: center;
}

.installContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 260px;
}
.kora_driving {
  height: 420px;
  width: 430px;
  margin-bottom: 120px;
}
.registrationContent {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items horizontally in the container */
  align-items: center; /* Center items vertically in the container */
  height: 100vh; /* Make the container full height of the viewport */
  text-align: center; /* Center text within the items, if needed */
  margin-top: 11%;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.qrCode{
  height:320px;
  margin-right: 50px;
}

.onboardingHeader {
  animation: fadeIn 2s ease-in-out;
  display: flex;
  flex-direction: column;
  color: #394337;
  font-size: 36px;
  font-style: normal;
  font-weight: 680;
  letter-spacing: 1px;
  text-align: left;
  margin-top: 0%;
  margin-bottom: 1%;
  padding: none;
}

.interceptorHeader {
  animation: fadeIn 2s ease-in-out;
  display: flex;
  flex-direction: column;
  color: #394337;
  font-size: 36px;
  font-style: normal;
  font-weight: 680;
  letter-spacing: 1px;
  text-align: center;
  margin-top: -10%;
  margin-bottom: 1%;
  padding: none;
}

.interceptorHeader2 {
  color: #63785e;
  margin: 1%;
  animation: fadeIn 2s ease-in-out;
  display: flex;
  flex-direction: column;
  font-size: 36px;
  font-style: normal;
  font-weight: 680;
  letter-spacing: 1px;
  text-align: center;
  padding: none;
}

.resultsHeader {
  margin: 1%;
  animation: fadeIn 2s ease-in-out;
  display: flex;
  flex-direction: column;
  color: #394337;
  font-size: 36px;
  font-style: normal;
  font-weight: 680;
  letter-spacing: 1px;
  text-align: left;
  padding: none;
}

.tiredbro {
  color: #63785e;
  margin: 1%;
  animation: fadeIn 2s ease-in-out;
  display: flex;
  flex-direction: column;
  font-size: 36px;
  font-style: normal;
  font-weight: 680;
  letter-spacing: 1px;
  text-align: left;
  padding: none;
}


.tiredbro2 {
  color: #63785e;
  font-size: 24px;
  margin: 0%;
}
.installHeader {
  animation: fadeIn 2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #394337;
  font-size: 36px;
  font-style: normal;
  font-weight: 680;
  line-height: 140%; /* 50.4px */
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 0%;
}

.installimage1 {
  width: auto;
  padding: 0.5%;
}

.overlayContainer {
  position: relative;
  display: inline-block; /* Or 'block' depending on your layout */
  justify-content: center;
  width: auto;
  bottom: 120px;
}

.proContainer {
  position: relative;
  display: inline-block; /* Or 'block' depending on your layout */
  justify-content: center;
  width: auto;
}

.basicplan{
  height:360px;
}

.subscribeContent {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center;
  justify-content: center; /* Center items horizontally in the container */
  height: 100vh; /* Make the container full height of the viewport */
  text-align: center; /* Center text within the items, if needed */
  margin-top: -10%;
}

.clipboard {
  width: 100%; /* Adjust based on your needs */
  height: auto; /* Maintain aspect ratio */
}

.clipboard2 {
  width: 90%; /* Adjust based on your needs */
  height: auto; /* Maintain aspect ratio */
}

.subscribeKora {
  height: 500px;
  width: 320px;
  animation: fadeIn 1s ease-in-out;
}

.subscribeButton {
  border-radius: 32px;
  background: var(--Primary-1, #394337);
  display: inline-flex;
  padding: 15px 46px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: large;
  font-weight: 900;
  margin: 2%;
  box-shadow: none; /* Remove drop shadow */
  border: 1px solid transparent; /* Optional: Add a subtle border */
}

.goBack {
  font-size: 12px;
  color: gray;
}

.subscribeHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #394337;
  font-size: 36px;
  font-style: normal;
  font-weight: 680;
  line-height: 140%; /* 50.4px */
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 0%;
}

.confirming {
  color: #63785e;
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-top: 0%;
}

.overlayInput1 {
  position: absolute;
  top: 50%; /* Adjust these values based on where you want the input */
  left: 50%;
  transform: translate(-50%, -50%); /* Center the input over the image */
  z-index: 10; /* Ensure the input is above the image */
  text-decoration: none !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: #394337;
  color: white;
  width: auto;
  max-width: 200px;
  min-height: 45px;
  text-align: center;
  font-size: 24px;
  border-radius: 12px;
  margin-bottom: 2%;
}

.overlayInput2 {
  position: absolute;
  top: 65%; /* Adjust these values based on where you want the input */
  left: 50%;
  transform: translate(-50%, -50%); /* Center the input over the image */
  z-index: 10; /* Ensure the input is above the image */
  text-decoration: none !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: #394337;
  color: white;
  width: auto;
  max-width: 200px;

  min-height: 45px;
  text-align: center;
  font-size: 24px;
  border-radius: 12px;
  margin-bottom: 2%;
}

.overlayInput3 {
  position: absolute;
  top: 40%; /* Adjust these values based on where you want the input */
  left: 50%;
  transform: translate(-50%, -50%); /* Center the input over the image */
  z-index: 10; /* Ensure the input is above the image */
  text-decoration: none !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: #394337;
  color: white;
  width: auto;
  max-width: 200px;
  min-height: 45px;
  text-align: center;
  font-size: 24px;
  border-radius: 12px;
  margin-bottom: 2%;
}

.registrationbutton {
  position: absolute;
  left: 50%;

  top: 80%; /* Adjust these values based on where you want the input */
  transform: translate(-50%, -50%); /* Center the input over the image */
  z-index: 10; /* Ensure the input is above the image */
  width: 60px;
  animation: fadeIn 2s ease-in-out;
}

.proButton {
  position: absolute;
  left: 50%;
  top: 84%; /* Adjust these values based on where you want the input */
  transform: translate(-50%, -50%); /* Center the input over the image */
  z-index: 10; /* Ensure the input is above the image */
  width: 60px;
  animation: fadeIn 2s ease-in-out;
}

.registrationTop {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.installText {
  animation: fadeIn 2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #394337;
  font-size: 24px;
  font-style: normal;
  font-weight: 680;
  line-height: 140%; /* 50.4px */
  letter-spacing: 1px;
  text-align: center;
}

.onboardingText {
  animation: fadeIn 2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: start;
  color: #394337;
  font-size: 24px;
  font-style: normal;
  font-weight: 680;
  line-height: 140%; /* 50.4px */
  letter-spacing: 1px;
  text-align: left;
}

.primaryHeader {
  color: #394337;
  font-size: 42px;
  font-style: normal;
  font-weight: 680;
  line-height: 140%; /* 50.4px */
  letter-spacing: 1px;
  text-align: center;
  max-height: 2%;
}

.landingHeader {
  color: #394337;
  font-size: 60px;
  margin: 1px;
}

.primaryText {
  color: #394337;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 50.4px */
  letter-spacing: 1.547px;
}

.landingText {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.onboardingTop {
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
}

.interfaceTop {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Add a valid value for justify-content */
  align-items: center;
  height: auto;
  width: auto;
}

.onboardingBottom {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
  height: 10px;
}

.interfaceBottom {
  display: flex;
  position: relative;
  margin-bottom: 2%;
}

.resultsBottom {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
}

.resultsTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  text-align: center;
}



.registrationBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.test {
  height: 0px;
  width: 0px;
}

.interfaceContent {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items horizontally in the container */
  align-items: center; /* Center items vertically in the container */
  height: 100vh; /* Make the container full height of the viewport */
  text-align: center; /* Center text within the items, if needed */
  margin-top: -30%;
}

.proContent {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items horizontally in the container */
  align-items: center; /* Center items vertically in the container */
  height: 100vh; /* Make the container full height of the viewport */
  text-align: center; /* Center text within the items, if needed */
}

.profileContent {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: start; /* Center items horizontally in the container */
  align-items: center; /* Center items vertically in the container */
  height: 100vh; /* Make the container full height of the viewport */
  text-align: center; /* Center text within the items, if needed */
  margin-top: 6%;
}

.shareButton {
  background: none;
  border: none;
}

@media only screen and (max-width: 320px) {
  .interfaceContent {
    justify-content: center; /* This will ensure the content is centered vertically */
    align-items: center; /* This will ensure the content is centered horizontally */
  }
}

.App-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background-color: #394337;
  color: #F7D9B9;
}

.occupy{
  width:40px;
    z-index: 1000; /* Adjust the value as needed to ensure it's higher than other elements */

}

.occupy1{
  width:40px;
  color:#F7D9B9;
  font-size: 12px;
  font-style: light;
  font-weight: 200px;
}

.interfaceHeader {
  color: #c4ac93;
  font-size: 24px;
  font-style: normal;
  font-weight: 680;
  line-height: 140%; /* 50.4px */
  letter-spacing: 1px;
  text-align: start;
  animation: fadeIn 1s ease-in-out;
}

.proHeader {
  color: #c4ac93;
  font-size: 24px;
  font-style: normal;
  font-weight: 680;
  line-height: 140%; /* 50.4px */
  letter-spacing: 1px;
  text-align: start;
  animation: fadeIn 1s ease-in-out;
}

.socialFooter {
  margin: 8%;
}

.profileHeader {
  color: #c4ac93;
  font-size: 24px;
  font-style: normal;
  font-weight: 680;
  line-height: 140%; /* 50.4px */
  letter-spacing: 1px;
  text-align: start;
  animation: fadeIn 1s ease-in-out;
}

.profileBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 2%;
}

.profileBody{
  width:42%;
  margin-top: -4%;
}

.proText {
  color: #394337;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 1px;
  width: 90%;
}

.options {
  position: absolute; /* This makes z-index applicable */
  z-index: 2; /* Higher value to bring in front */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Add this line */
  justify-content: center;
  width: auto;
  animation: fadeIn 1s ease-in-out;
  margin-top: 100%;
}

.options2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Add this line */
  justify-content: center;
  width: auto;
  height: auto;
  animation: fadeIn 2s ease-in-out;
}

.interfacebox {
  min-width: auto;
  max-width: 140px;
  margin: 0.5%;
}

.optionbox {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c4ac93;
  color: white !important;
  margin: 0.6%;
  border-radius: 12px;
  width: auto;
  text-align: center;
  padding: 6px 12px;
  font-size: 24px;
  text-decoration: none !important;
  margin: 0.6%;
}

.optiontext {
  color: white;
  text-decoration: none !important;
}

.results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2%;
  height: auto;
  width: auto;
  background-color: #63785e;
  border-radius: 32px;
  padding: 8px;
}

.resultsbox {
  text-decoration: none !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: #394337;
  color: white;
  min-width: 320px;
  min-height: 80px;
  text-align: center;
  font-size: 24px;
  border-radius: 32px;
  margin-bottom: 2%;
}

.chatbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: #394337;
  color: white;
  min-width: 320px;
  min-height: 80px;
  text-align: center;
  font-size: 24px;
  border-radius: 32px;
}

.chatEntries{
  background-color: #DCAB74;
  margin-top: 80px;
  position: absolute;
  z-index: 1000; /* Ensures it's on top of other elements */

}

.chatentry {
  background-color: #394337;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
}

.regenerate {
  text-decoration: none !important;
  color: #63785e !important;
  font-size: 20px;
}

.check {
  justify-content: flex-end;
  justify-self: right;
  height: 40px;
  margin: 20px;
  background-color: #394337;
}

.redo {
  height: 30px;
  color: #c4ac93;
}

.intefaceBody {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0px;
}

.chatsubmit {
  justify-content: flex-end;
  justify-self: right;
  height: 40px;
  margin: 20px;
  background-color: #394337;
}

.custom-link {
  color: #fff7ef; /* Replace with your desired color */
  text-decoration: none;
  font-weight: bold;
}

.custom-link:hover {
  color: #cdd9ea; /* Optional: a different color on hover */
  text-decoration: underline;
}
